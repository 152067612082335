<div class="conteiner">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-12 col-md-4 card-img">
            <img src="assets/img/BATERIAS.jpg" class="size-img float-right">
            
        </div>
        <div class="col-8 col-md-4 card-text">
            <div class="card" id="tarjeta">
                <div class="card-body">
                    <h5 class="card-title">Agenda tu atención en sucursal</h5>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">Sucursal:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="field" >
                                <div class="control has-icons-left">
                                    <select [ngModel]="oficina" (ngModelChange)="onChangeSelection($event, 'Ofi')" class="select-custom">
                                        <option value="" *ngIf="!bOfiSel" class="labelSelect">Sucursal</option>
                                        <option *ngFor="let ofi of oficinas"
                                            [value]="ofi.idOfi"
                                            [selected]="bOfiSel" class="labelSelect">{{ ofi.oficina }}</option>
                                    </select>
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-building icon-desk" style="color: #E65100;"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">Servicios:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="control has-icons-left">
								<select  [ngModel]="serie" (ngModelChange)="onChangeSelection($event, 'Ser')" [disabled]="!bSerie" class="select-custom">
									<option value="" *ngIf="!bSerieSel" class="labelSelect" >Agendamiento web</option>
									<option *ngFor="let ser of series"
										[value]="ser.idSerie"
										[selected]="bSerieSel" class="labelSelect">{{ ser.serie }}</option>
								</select>
                                <span class="icon is-small is-left">
                                    <i class="fas fa-lightbulb icon-desk" style="color: #E65100;"></i>
                                </span>
                            </div>
                        </div>
                    </div>
					<div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">Fecha:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="control has-icons-left">
								<input placeholder="dd/mm/aaaa" type="text" class="select-custom" [ngModel]="fecha" (ngModelChange)="onChangeSelection($event, 'Fec')" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig" readonly [disabled]="!bFecha"/>
                                <span class="icon is-small is-left">
                                    <i class="fas fa-calendar-alt icon-desk" style="color: #E65100;"></i>
                                </span>
                            </div>
                        </div>
                    </div>
					<div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">Hora:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="control has-icons-left">
								<select [ngModel]="hora" (ngModelChange)="onChangeSelection($event, 'Hor')"  [disabled]="!bHora" class="select-custom">
									<option value="" class="labelSelect">Hora</option>
									<option *ngFor="let hor of horas"
										[value]="hor.hora"
										[disabled]="!hor.ok"
										[ngClass]="{ horDis: !hor.ok }" class="labelSelect">{{ hor.hora }}</option>
								</select>
                                <span class="icon is-small is-left">
                                    <i class="fas fa-clock icon-desk" style="color: #E65100;"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="columms is-flex is-full">
                        <div class="buttons">
                            <div class="control centered-box">
                                <button class="button agendar-button" (click)="agendar()" [disabled]="!bAgendar"
                                    [ngClass]="{'disabled-button': !bAgendar}">
                                        Agendar Turno
                                </button>
                                <a href="#" class="is-block back-button" (click)="inicio()">Volver</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</div>