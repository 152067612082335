<div class="conteiner">
  <div class="row">
      <div class="col-2"></div>
      <div class="col-12 col-md-4 card-img">
          <img src="assets/img/PLUMILLAS.jpg" class="size-img float-right">
          
      </div>
      <div class="col-8 col-md-4 card-text">
          <div class="card" id="tarjeta">
              <div class="card-body">
                <div class="text-center">
                  <img src="assets/img/check.png">
                  <h5 class="card-title text-center">Tu hora ha sido asignada con éxito</h5>
                  <p class="card-title2 text-center">Hemos enviado un comprobante a tu correo electrónico</p>
                </div>
                  
                  
                  <div class="container text-center">
          <div class="row">
            <div class="col-12 col-md-6  labelsLeft2">
              <label class="labelsLeft">Sucursal</label>
            </div>
            <div class="col-12 col-md-6 labelsRight2">
              <input class="input is-static labelsRight" type="email" value="{{ oficina }}" readonly />
            </div>
            <div class="col-12 col-md-6  labelsLeft2">
              <label class="labelsLeft">Tipo de atención</label>
            </div>
            <div class="col-12 col-md-6  labelsRight2">
              <input class="input is-static labelsRight" type="email" value="{{ serie }}" readonly />
            </div>
            <div class="col-12 col-md-6  labelsLeft2">
              <label class="labelsLeft">Día</label>
            </div>
            <div class="col-12 col-md-6  labelsRight2">
              <input class="input is-static labelsRight" type="email" value="{{ fecha | date: 'dd/MM/yyyy' }}" readonly />
            </div>
            <div class="col-12 col-md-6  labelsLeft2">
              <label class="labelsLeft">Hora</label>
            </div>
            <div class="col-12 col-md-6  labelsRight2">
              <input class="input is-static labelsRight" type="email" value="{{ hora }}" readonly />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <div class="col-10 title3">
            <p>Te recomendamos asistir a la sucursal con anticipación y anunciar tu llegada con el anfitrión</p>
          </div>
          <div class="col-1"></div>
          </div>
          <div class="columms is-flex-tablet is-full">
            <div class="field">
              <div class="buttons is-centered">
                <a href="#" class="is-block back-button" (click)="exit()">Volver</a>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
</div>