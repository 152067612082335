<div class="conteiner">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-12 col-md-4 card-img">
            <img src="assets/img/AMPOLLETAS.jpg" class="size-img float-right">
            
        </div>
        <div class="col-8 col-md-4 card-text">
            <!-- <div class="card"  [ngStyle]="{'height.px': isMobile ? 500 : 380}"> -->
            <div class="card" id="tarjeta">
                <form [formGroup]="filterForm" #f="ngForm" (ngSubmit)="formSubmit(f)">
                <div class="card-body">
                    <h5 class="card-title">Instala gratis Baterías, Plumillas y Ampolletas</h5>
                    <p class="card-title2">Ingresa tu RUT y correo electrónico para agendar tu hora online.</p>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">RUT:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="control has-icons-left">
                                <input class="input" type="text" placeholder="RUT sin puntos y con guión" [ngModel]="rut" (ngModelChange)="formateaRut($event)" [ngClass]="{
                                    'is-danger':
                                        (filterForm.controls['rut'].errors?.required ||
                                        filterForm.controls['rut'].errors?.invalidrut) &&
                                        filterForm.controls['rut'].touched,
                                    'is-success':
                                        filterForm.controls['rut'].errors == null &&
                                        filterForm.controls['rut'].touched
                                }" formControlName="rut" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user icon-desk" style="color: #E65100;"></i>
                                </span>
                                <div *ngIf=" filterForm.controls['rut'].touched && (filterForm.controls['rut'].errors?.required || filterForm.controls['rut'].errors?.invalidrut)">
                                    <p class="help is-danger">Por favor ingresa un RUT válido.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">Correo Electrónico:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="control has-icons-left">
                                <input class="input" type="text" placeholder="Correo Electrónico" [ngClass]="{
                                    'is-danger':
                                        (filterForm.controls['email'].errors?.required ||
                                        filterForm.controls['email'].errors?.pattern) &&
                                        filterForm.controls['email'].touched,
                                    'is-success':
                                        filterForm.controls['email'].errors == null &&
                                    filterForm.controls['email'].touched
                                }" formControlName="email" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-envelope icon-desk" style="color: #E65100;"></i>
                                </span>
                                <div *ngIf=" filterForm.controls['email'].touched && filterForm.controls['email'].errors?.required || filterForm.controls['email'].errors?.pattern">
                                    <p class="help is-danger">Por favor ingresa un email válido.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label class="label titleLabels">Teléfono:</label>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="control control has-icons-left">
                                <input class="input" type="text" placeholder="Teléfono" [ngClass]="{
                                    'is-danger':
                                        (filterForm.controls['telefono'].errors?.required ||
                                        filterForm.controls['telefono'].errors?.invalidPhone) &&
                                        filterForm.controls['telefono'].touched,
                                    'is-success':
                                        filterForm.controls['telefono'].errors == null &&
                                        filterForm.controls['telefono'].touched
                                }" formControlName="telefono" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-phone icon-desk" style="color: #E65100;"></i>
                                </span>
                                <div *ngIf="filterForm.controls['telefono'].touched && filterForm.controls['telefono'].errors?.invalidPhone">
                                    <p class="help is-danger">Por favor ingresa un teléfono válido.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columms is-flex is-full">
                        <div class="buttons">
                            <div class="control centered-box">
                                <button type="submit" class="button next-button" [disabled]="!filterForm.valid"
                                [ngClass]="{'disabled-button': !filterForm.valid}">
                                Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</div>