<app-header></app-header>
<snackalert></snackalert>

<body>
  <div class="container">
    
  </div>
  <ngx-spinner type="ball-triangle-path"></ngx-spinner>
  <router-outlet></router-outlet>
</body>
<app-footer></app-footer>
