import { Component, OnInit, Input } from '@angular/core';
import { AgendarService } from '../services/agendar.service';
import { SnackalertService } from '../directives/snackalert/service/snackalert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidator } from '../validators/custom.validator';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from '../services/title.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit  {
  filterForm: FormGroup;
  oficinas: object = [];
  rut: string = '';

  constructor(
    private agendarService: AgendarService,
    private snackalertService: SnackalertService,
    private customValidator: CustomValidator,
    private router: Router,
    private titleService: TitleService, 
  ) {

    this.filterForm = new FormGroup({
      rut: new FormControl('', [
        Validators.required,
        customValidator.invalidRut,
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        ),
      ]),
      telefono: new FormControl('', [
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        customValidator.invalidPhone,
      ]),
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Bienvenido al agendamiento de atención en sucursal');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('Oficina');
    sessionStorage.removeItem('Serie');
    sessionStorage.removeItem('Turno');
    sessionStorage.removeItem('rut');
    sessionStorage.removeItem('tel');
    sessionStorage.removeItem('cor');
    sessionStorage.removeItem('ofi');
    sessionStorage.removeItem('ser');
    sessionStorage.removeItem('fec');
    sessionStorage.removeItem('hor');
  }


  formSubmit(form) {
    sessionStorage.setItem('rut', this.filterForm.get('rut').value);
    sessionStorage.setItem('cor', this.filterForm.get('email').value);
    sessionStorage.setItem('tel', this.filterForm.get('telefono').value);
    this.router.navigate(['/parsel']);
  }

  listar() {
    sessionStorage.setItem('rut', this.filterForm.get('rut').value);
    this.router.navigate(['/horcan']);
  }

  formateaRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, '');
      var rutPuntos = '';
      if (actual != '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, '');
        var actualLimpio = sinPuntos.replace(/-/g, '');
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);

        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          //en caso de querer puntos habilitar lo siguiente
          /* if (j % 3 == 0 && j <= inicio.length - 1) {
                  rutPuntos = "." + rutPuntos;
              }*/
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + '-' + dv;
      } else {
        rutPuntos = rut;
      }
      this.rut = rutPuntos;
    } else {
      this.rut = this.rut;
    }
  }


}
